<script lang="ts" setup>
const { t } = useT();
const route = useRoute();
const isGuest = useIsGuest();
const { data } = useAppInitData();
const localPath = useLocalePath();
const { handleSupportButtonClick } = useSupportChat();
const { data: homeData } = useHomeData({ immediate: false });
const [expanded, toggleExpanded] = useToggle();

const hasDeposits = computed(() => data.value?.hasDeposits);
const isShowAffiliate = computed(() => !isGuest.value && data.value?.isAffiliate);

const termsLinks = computed(() =>
	data.value?.footer?.menu
		?.find((item) => item?.title === "second_column")
		?.items?.filter(
			(link) => !((link?.slug === "promotional-games-rules" || link?.slug === "refund-policy") && !hasDeposits.value)
		)
		?.filter(
			(link) => !((link?.slug === "affiliate-agreement" || link?.slug === "partners-rules") && !isShowAffiliate.value)
		)
		?.filter((link) => !(link?.slug === "alternative-method-of-entry" && isGuest.value))
);
const seoText = computed(() => homeData.value?.seo?.seoText);

const socials = [
	{
		id: "fb",
		name: "facebook",
		href: data.value?.facebookJoinLink
	}
] as const;
</script>
<template>
	<footer class="footer">
		<div class="terms-list">
			<div class="links">
				<NuxtLink
					v-for="(link, index) in termsLinks"
					:key="link.slug"
					:data-tid="`footer-terms-${index}`"
					:to="localPath(`/page/${link.slug}`)"
				>
					<AText class="text-cannes" variant="toledo" :modifiers="['link', 'underline']">
						{{ t(link.title ?? "") }}
					</AText>
				</NuxtLink>

				<NuxtLink v-if="!isGuest" :to="localPath('/referral-info/')">
					<AText
						class="text-cannes"
						variant="toledo"
						data-tid="footer-referral-info"
						:modifiers="['link', 'underline']"
						>{{ t("Affiliate") }}</AText
					>
				</NuxtLink>

				<NuxtLink v-if="!isGuest" :to="localPath('/how-it-works/')">
					<AText
						class="text-cannes"
						variant="toledo"
						data-tid="footer-how-it-works"
						:modifiers="['link', 'underline']"
						>{{ t("How it works") }}</AText
					>
				</NuxtLink>
			</div>
			<div class="social-link">
				<template v-for="(social, index) in socials" :key="index">
					<a :href="social.href || ''" target="_blank" :data-tid="`footer-social-${social?.id}`" :class="social.name">
						<NuxtIcon :name="`24/${social.id}`" filled />
						<div>
							<AText variant="taranto" :modifiers="['uppercase']">{{ t("Join to") }}</AText>
							<AText variant="tivat">{{ t(social.name) }}</AText>
						</div>
					</a>
				</template>
			</div>
		</div>
		<div class="support">
			<div class="support-links">
				<AText
					as="div"
					variant="toledo"
					data-tid="footer-support"
					:modifiers="['link', 'underline']"
					@click="handleSupportButtonClick"
				>
					<NuxtIcon name="20/support" filled />
					{{ t("Online support") }}
				</AText>
				<AText
					as="a"
					variant="toledo"
					data-tid="footer-support-email"
					:modifiers="['link', 'underline']"
					:href="`mailto:${t('emailItendity')}@${t('emailDomain')}`"
				>
					<NuxtIcon name="20/mail" filled />
					{{ t("emailItendity") }}@{{ t("emailDomain") }}
				</AText>
				<!-- <OLangSwitcher /> -->
			</div>
			<div class="review">
				<a href="https://www.trustpilot.com/review/funrize.com" target="_blank" data-tid="footer-trustpilot">
					<NuxtImg src="/nuxt-img/footer/trustpilot.png" alt="trustpilot" format="avif" width="216" height="80" />
				</a>
				<ASvg name="40/age" class="age-icon" />
			</div>
		</div>
		<div v-if="route.matched?.[0]?.path === localPath('/')" class="seo">
			<ACollapse :expanded="expanded">
				<template #title>
					<div data-tid="footer-seo" :class="['seo-button', { active: expanded }]" @click="toggleExpanded()">
						<AText variant="toledo">{{ t("Funrize: Free to Play Social Casino") }}</AText>
						<NuxtIcon name="16/arrow-down-small" filled />
					</div>
				</template>
				<template #expanded>
					<div class="seo-text" v-html="seoText" />
				</template>
			</ACollapse>
		</div>
		<div class="about" data-tid="footer-about">
			<AText variant="topeka" as="p">
				{{
					t(
						"NO PURCHASE IS NECESSARY to enter free game promotion (“promotional games”). PROMOTIONAL GAMES ARE VOID WHERE PROHIBITED BY LAW. For detailed rules, see Terms of Use."
					)
				}}
			</AText>
			<AText variant="topeka" as="p">
				{{
					t(
						"Funrize™ Social Gaming Platform is a play-for-fun website intended for amusement purposes only. Funrize™ Social Gaming Platform does not offer “real-money gambling” or opportunity to win real money based on a gameplay."
					)
				}}
			</AText>
			<AText variant="topeka" as="p">
				{{
					t(
						"Funrize™ Social Gaming Platform is only open to Eligible Participants, who are at least eighteen (18) years old or the age of majority in their jurisdiction (whichever occurs later) at the time of entry."
					)
				}}
			</AText>
		</div>
		<AText class="copyright" data-tid="footer-copyright" :modifiers="['center']" as="div">
			<i18n-t keypath="{key1} {key2} Funrize™ Social Gaming Platform" tag="div">
				<template #key1> &copy; </template>
				<template #key2> {{ new Date().getFullYear() }} </template>
			</i18n-t>
			<div>{{ t("US Payment Related Queries (24/7):") }}</div>
			<a class="text-cannes" :href="`tel:${t('+12088261426')}`">{{ t("+1 (208) 826-1426") }}</a>
		</AText>
	</footer>
</template>

<style scoped lang="scss">
.footer {
	display: flex;
	flex-direction: column;
	background-color: var(--cacem);
	margin-left: var(--o-sidebar-width);
	width: calc(100% - var(--o-sidebar-width));

	@include media-breakpoint-down(lg) {
		width: 100%;
		margin-left: 0;
		padding-bottom: gutter(6);
	}

	> div {
		padding: gutter(2) gutter(7);

		@include media-breakpoint-down(lg) {
			padding: gutter(2);
		}

		&:not(:first-child) {
			border-top: 1px solid var(--cordoba);
		}
	}
}

.terms-list {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@include media-breakpoint-down(lg) {
		align-items: flex-start;
		flex-direction: column;
		gap: gutter(2);
	}

	.links {
		display: inline-flex;
		align-items: center;
		gap: gutter(1) gutter(3);
		flex-wrap: wrap;

		@include media-breakpoint-down(lg) {
			flex-direction: column;
			align-items: flex-start;
			gap: gutter(2);

			span {
				font-size: 14px;
			}
		}

		a:hover span {
			text-decoration: none;
		}
	}
}

.social-link {
	display: inline-flex;
	align-items: center;
	flex-shrink: 0;
	gap: gutter(2);

	@include media-breakpoint-up(lg) {
		margin-left: gutter(2);
	}

	a {
		display: flex;
		align-items: center;
		height: 32px;
		width: 96px;
		transition: 0.3s;
		padding-left: gutter(0.75);
		gap: gutter(0.5);
		border-radius: 4px;
		background: var(--caen);
		color: var(--cannes);

		&.twitter {
			background: var(--campo);
		}

		> div {
			display: flex;
			flex-direction: column;
			line-height: 1;
		}

		&:hover {
			opacity: 0.8;
		}

		&:deep(svg) {
			font-size: 20px;
		}
	}
}

.support {
	display: flex;
	justify-content: space-between;

	@include media-breakpoint-down(lg) {
		flex-direction: column;
		align-items: flex-start;
		gap: gutter(2);
	}

	.support-links {
		display: flex;
		flex-direction: column;
		gap: gutter(2);

		.link {
			display: inline-flex;
			align-items: center;
			gap: gutter(1);

			:deep(svg) {
				margin: 0;
			}

			&:hover {
				text-decoration: none;
			}
		}
	}

	.review {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-shrink: 0;
		width: 208px;

		img {
			width: 108px;
			height: 40px;
		}

		@include media-breakpoint-down(lg) {
			width: 100%;
		}
	}

	.age-icon {
		font-size: 40px;
	}
}

.about,
.copyright {
	display: flex;
	flex-direction: column;
	gap: gutter(3);
	color: var(--changde);
	font-weight: 300;
}

.about {
	line-height: 20px;
	gap: gutter(2);
}

.copyright a {
	display: inline-block;
	margin: gutter(-1.5) 0 gutter(2);
}

.seo {
	&-button {
		display: flex;
		align-items: center;
		gap: gutter(1);
		cursor: pointer;
		color: var(--cannes);

		.nuxt-icon {
			font-size: 22px;
			line-height: 1;
			transition: all 200ms ease-in-out;

			:deep(svg) {
				margin-bottom: 0;
			}
		}
		&.active .nuxt-icon {
			transform: rotate(180deg);
		}
	}

	&-text {
		color: var(--changde);
		font-size: 14px;
		line-height: 1.35;
		font-weight: 300;
		margin-top: gutter(1);

		&:deep(p) {
			text-align: justify;
			line-height: 1.375;
			margin-bottom: gutter(3);
		}

		&:deep(ul) {
			margin: 0 0 gutter(2.5) gutter(1.75);
			li {
				list-style-type: disc;
				line-height: 1.375;
				margin-bottom: gutter(1.25);
			}
		}

		&:deep(h2) {
			font-size: 26px;
			line-height: 1.167;
			font-weight: 500;
			color: var(--cannes);
			margin-bottom: gutter(2.5);
		}

		&:deep(h3) {
			font-size: 24px;
			line-height: 1;
			color: var(--cannes);
			font-weight: 500;
			margin-bottom: gutter(2.5);
		}
	}
}
</style>
